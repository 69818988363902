import React, { useCallback, useEffect } from "react";
import {
  Box,
  Center,
  Text,
  Image,
  Divider,
  Checkbox,
  VStack,
  HStack,
  Heading,
} from "@chakra-ui/react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { colors, other } from "src/theme";
import {
  DefaultErrors,
  failure,
  FailureOrSuccess,
  Maybe,
  success,
  UnexpectedError,
} from "src/core";
import { useLazyQuery, useMutation } from "@apollo/client";
import { api, apolloClient } from "src/api";
import {
  Mutation,
  MutationCreateUserArgs,
  Query,
} from "src/api/generated/types";
import { auth } from "src/utils/firebase";
import { BaseUserFields } from "src/api/fragments";
import { signInWithCustomToken, signOut } from "firebase/auth";
import { getRedirectPath } from "../utils";
import { GoogleButton } from "../components/Google";
import { useMyToast } from "src/hooks";
import { isNil } from "lodash/fp";
import { AppleButton } from "../components/Apple";
import { useTheme } from "src/hooks/useTheme";
import { Input } from "src/components/Form";
import { Button } from "src/components/Button";
import { useSelector } from "react-redux";
import { getUserAuthStatus } from "src/redux/reducers/user";
import { FacebookEventName, pixel } from "src/utils/pixel";
import { useIsLargeScreen } from "src/hooks/useScreenSize";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const SignupImage = require("src/assets/signup.jpg");

const schema = yup.object().shape({
  fullName: yup.string().required("Full name is required.").nullable(),
  email: yup.string().email().required("Email is required.").nullable(),
  password: yup.string().min(6).required("Password is required.").nullable(),
});

type FormValues = {
  email: string;
  password: string;
  fullName: string;
};

const DEFAULT_VALUES: FormValues = {
  email: "",
  password: "",
  fullName: "",
};

export function Signup() {
  const [search] = useSearchParams();
  const toast = useMyToast();
  const navigate = useNavigate();

  const authStatus = useSelector(getUserAuthStatus);

  const phoneNumber = search.get("phoneNumber") || null;
  const isElder = (search.get("isElder") || null) === "true";

  const email = search.get("email") || null;
  const fullName = search.get("fullName") || null;

  // API hooks
  const [createUser] = useMutation<Pick<Mutation, "createUser">>(
    api.users.create
  );

  const [getMe] = useLazyQuery<{ me?: BaseUserFields }>(api.users.me, {
    fetchPolicy: "no-cache",
  });

  const [getActiveFamily] = useLazyQuery<Pick<Query, "getActiveFamily">>(
    api.families.getActiveFamily
  );

  const [getActiveFamilyMember] = useLazyQuery<
    Pick<Query, "getActiveFamilyMember">
  >(api.families.getActiveFamilyMember);

  // Form hooks
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...DEFAULT_VALUES,
      email: email || "",
      fullName: fullName || "",
    },
  });

  const _logAuthError = ({ message }: { message: string }) => {
    toast.show({ message, status: "error" });
  };

  const _navigateForUser = async () => {
    try {
      const response = await getActiveFamily();
      const activeFamilyMember = await getActiveFamilyMember();

      const familySlug = response?.data?.getActiveFamily?.slug;
      const activeMember = activeFamilyMember?.data?.getActiveFamilyMember;
      const hasPhone = activeMember?.phone;

      // just skip to the welcome and set them to is elder
      if (hasPhone) {
        return navigate(
          `/app/${familySlug}/welcome?isElder=${activeMember.isElder}`
        );
      }

      // alert(familySlug);

      // if the user has a valid referred by code -> we want to redirect them to a welcome page

      return navigate(`/app/${familySlug}/test-call`);
    } catch (err) {
      return navigate(`/app`);
    }
  };

  // Functions
  const _createUser = useCallback(
    async (
      values: FormValues
    ): Promise<FailureOrSuccess<DefaultErrors, BaseUserFields>> => {
      try {
        const referredDomainUrl = window.location.hostname;
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const params: MutationCreateUserArgs = {
          email: values.email,
          name: values.fullName,
          password: values.password,
          timezone: timezone,
          phoneNumber: phoneNumber,
          isElder: isElder,
        };

        const userResponse = await createUser({
          variables: params,
          refetchQueries: [api.users.me],
        });

        if (!userResponse.data?.createUser) {
          return failure(new Error("No user returned."));
        }

        const { token, user } = userResponse.data.createUser;

        await signInWithCustomToken(auth, token);

        apolloClient.refetchQueries({
          include: [api.users.me],
        });

        pixel.track(FacebookEventName.Signup);

        return success(user);
      } catch (err) {
        return failure(new UnexpectedError(err));
      }
    },
    [createUser]
  );

  const onSubmit = useCallback(
    async (values: FormValues) => {
      const currentUser = auth.currentUser;

      // if there is already a user locally and they have the same email,
      // check to see if they already exist in our db. and if not create the user otherwise can return
      if (currentUser && currentUser.email === values.email) {
        const res = await getMe();

        // if the user is trying to create an account for an email they are already signed in as,
        // just move them to the dashboard because they are good
        if (res.data?.me) {
          return _navigateForUser();
        }

        // if there is already a user for this email and they are already logged in with the firebase
        // user we just need to create their user in our system and send them to the dashboard
        // Note: this is safe because the authentication header will be set with a jwt that verified legitimacy
        // of the firebase account
        if (res.error || !res.data?.me) {
          const userResponse = await _createUser(values);

          if (userResponse.isSuccess()) {
            return _navigateForUser();
          }
        }
      }

      const userResponse = await _createUser(values);

      if (userResponse.isFailure()) {
        return toast.show({
          status: "error",
          message: userResponse.error.message,
        });
      }

      return _navigateForUser();
    },
    [_createUser]
  );

  const theme = useTheme();
  const isLarge = useIsLargeScreen();

  return (
    <div
      style={{
        backgroundColor: colors.primary,
      }}
    >
      <HStack
        padding="0"
        minH="100vh"
        display="flex"
        w="100%"
        h="100vh"
        gap={0}
      >
        <Box
          display="flex"
          flexDir="column"
          h="100%"
          overflowY="scroll"
          w="100%"
          maxW={!isLarge ? "100%" : "500px"}
          marginRight={0}
          flex={1}
          padding="2rem"
          bg={theme.background}
        >
          <form
            onSubmit={handleSubmit(onSubmit, (e) =>
              toast.show({
                status: "error",
                message:
                  e.email?.message ||
                  e.fullName?.message ||
                  e.password?.message ||
                  "",
              })
            )}
          >
            <Heading
              textAlign="left"
              color={theme.header}
              className="spectral-bold"
            >
              Get started with Quickfix
            </Heading>

            {!isLarge && (
              <div
                style={{
                  padding: "15px 15px 5px 15px",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  borderRadius: 15,
                  backgroundColor: theme.secondaryBackground,
                }}
              >
                <HStack spacing={1} align="center">
                  <i
                    className="fa fa-star"
                    style={{ color: colors.orange50 }}
                  />
                  <i
                    className="fa fa-star"
                    style={{ color: colors.orange50 }}
                  />
                  <i
                    className="fa fa-star"
                    style={{ color: colors.orange50 }}
                  />
                  <i
                    className="fa fa-star"
                    style={{ color: colors.orange50 }}
                  />
                  <i
                    className="fa fa-star"
                    style={{ color: colors.orange50 }}
                  />
                </HStack>
                <Text
                  style={{
                    position: "relative",
                    top: -5,
                    marginTop: 0,
                    fontFamily: "Spectral",
                  }}
                >
                  <i
                    className="fa fa-quote-left"
                    style={{ color: colors.primary }}
                  />
                  <br />I was wondering why my mom was so happy today? Turns out
                  she called Quickfix.
                  <div style={{ textAlign: "right" }}>
                    <i
                      className="fa fa-quote-right"
                      style={{ color: colors.primary, alignSelf: "flex-end" }}
                    />
                  </div>
                </Text>
              </div>
            )}

            <br />

            <GoogleButton
              label="Sign up"
              onError={_logAuthError}
              onSuccess={(u, fb, isNew) => _navigateForUser()}
            />
            <br />
            <br />

            {/* <AppleButton
              label="Sign up"
              onError={_logAuthError}
              onSuccess={(u, fb, isNew) =>
              }
            /> */}

            {/* <br />
            <br /> */}

            <Divider />

            <br />

            <Box width="100%">
              <Input
                label="Full Name"
                isRequired
                control={control}
                autoComplete="name"
                name="fullName"
                // fill in the fields to autosuggest the full name
              />
              <Input
                label="Email"
                isRequired
                autoComplete="email"
                control={control}
                name="email"
              />
              <Input
                label="Password"
                isRequired
                control={control}
                type="password"
                autoComplete="new-password"
                name="password"
              />
            </Box>

            {/* <Text textAlign="center" fontSize="xs" color={theme.text}>
              By signing up, you opt-in to receiving calls / sms messages from
              Quickfix.
            </Text> */}

            <br />

            <Button
              isLoading={isSubmitting}
              width="100%"
              variant="primary"
              type="submit"
              padding="1rem 2rem"
            >
              Sign up
            </Button>

            <Divider margin="2rem auto" />

            <Text margin="auto" color={theme.text}>
              Already have an account?&nbsp;
              <Box display="inline-block" textDecor="underline" color="black">
                <Link to="/login">
                  <Text color={colors.primary}>Log in</Text>
                </Link>
              </Box>
            </Text>

            <br />
            <br />
            <br />
          </form>
        </Box>

        {isLarge && (
          <div
            style={{
              flex: 1,
              height: "100%",
              minHeight: "100vh",
              backgroundColor: colors.primary,
              // background of SignupImage, contain
              background: `url(${SignupImage})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          />
        )}
      </HStack>
    </div>
  );
}
